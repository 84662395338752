module.exports = [{
      plugin: require('/tmp/4e503a65/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"showCaptions":true,"tracedSVG":true},
    },{
      plugin: require('/tmp/4e503a65/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/tmp/4e503a65/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"runtimeCaching":[{"urlPattern":{},"handler":"cacheFirst"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"},{"urlPattern":{},"handler":"staleWhileRevalidate"}]},
    },{
      plugin: require('/tmp/4e503a65/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
